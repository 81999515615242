
import { updateAppConfig } from '#app'
import { defuFn } from '/usr/local/app/node_modules/.pnpm/defu@6.1.2/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "storefront": {
    "name": "Vuci"
  },
  "nuxt": {
    "buildId": "11be87ae-1beb-4a11-9d7d-635e6dd22657"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/usr/local/app/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
